import { LockOpenRounded, LogoutRounded, Person } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { logout, setAuthorized } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user, authorized } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { api } = useAsyncRequest();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    localStorage.clear();
    // window.location.replace("/api/v2/user/user/logout/");
    try {
      var res = await axiosInstance.get(arkadu.urls.v2.user.logout);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("unable to logout"), {
        variant: "error",
      });
    }

    try {
      handleClose();
      dispatch(logout());
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("unable to logout"), {
        variant: "error",
      });
    }
  };
  if (!user) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Badge
          badgeContent={
            authorized ? (
              <LockOpenRounded size="small" sx={{ height: 16, width: 16 }} />
            ) : (
              0
            )
          }
          color="success"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <>
            <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            <Hidden lgDown>
              <Typography variant="h6" color="inherit">
                {user.name}
              </Typography>
            </Hidden>
          </>
        </Badge>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        // getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={() => {
            history.push("/app/account");
          }}
        >
          <ListItemIcon size="small">
            <Person color="info" />
          </ListItemIcon>
          <ListItemText>{t("Cuenta")}</ListItemText>
        </MenuItem>
        {authorized && (
          <MenuItem
            onClick={() => {
              dispatch(setAuthorized(false));
            }}
          >
            <ListItemIcon size="small">
              <LockOpenRounded color="info" />
            </ListItemIcon>
            <ListItemText>{t("Bloquear")}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon size="small">
            <LogoutRounded color="primary" />
          </ListItemIcon>

          <ListItemText>{t("Cerrar sesi�n")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
