import { AutoDeleteRounded, AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill-new";
import useAI from "src/hooks/useAI";
import AIActionButton from "./AIActionButton";

const useStyles = makeStyles({
  previewIA: {
    fontFamily: "Roboto,Helvetica, Arial, sans-serif",
    fontWeight: "400",
    "& li": {
      marginLeft: "2rem",
    },
  },
});

const CustomTextEditor = ({
  variant,
  label,
  onChange,
  initialValue,
  disabled,
  textarea,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [thisState, setThisState] = React.useState({
    value: "",
    error: null,
    editorContent: initialValue || "",
    plainText: initialValue || "",
    waitingForAI: false,
    aiResponse: null,
  });
  const { generateContent } = useAI();

  const handleChange = (html, d, s, editor) => {
    if (onChange) {
      onChange(html, {
        text: editor.getText(),
      });
    }
    var plain = editor.getText();

    setThisState({
      ...thisState,
      editorContent: html,
      plainText: plain,
    });
  };

  const handleIARedaction = () => {
    if (thisState.plainText?.length < 50) {
      setThisState({
        ...thisState,
        error: t("El texto es muy corto"),
      });
      return;
    }
    setThisState({
      ...thisState,
      waitingForAI: true,
    });
    generateContent(
      `RETORNA HTML: ${thisState.editorContent}`,
      "redactor"
    ).then((res) => {
      if (res.res === 0) {
        setThisState({
          ...thisState,
          error: res.message,
          waitingForAI: false,
        });
        return;
      } else {
        setThisState({
          ...thisState,
          waitingForAI: false,
          aiResponse: res.data.replace("```html", "").replace("```", ""),
        });
      }
    });
  };

  React.useEffect(() => {
    setThisState({
      ...thisState,
      editorContent: initialValue || "",
      plainText: initialValue || "",
    });
  }, [initialValue]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            minHeight: "50px !important",
            "& .ql-container": {
              minHeight: "50px !important",
              color: "black",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {label && <Typography variant="h3">{label}</Typography>}
            </Grid>
            {!disabled && (
              <Grid item xs={6} textAlign="right">
                <AIActionButton
                  onClick={handleIARedaction}
                  label={t("Mejorar redacci�n con IA")}
                  color="secondary"
                />
              </Grid>
            )}
          </Grid>
          {thisState.error && (
            <FormHelperText error>{thisState.error}</FormHelperText>
          )}
        </Grid>
        {thisState.aiResponse && (
          <Grid
            item
            xs={12}
            sx={{
              border: "1px solid #ccc",
              padding: "10px",
              margin: "10px",
              borderRadius: "5px",
            }}
          >
            <FormHelperText>{t("Texto mejorado")}</FormHelperText>
            <Grid
              item
              dangerouslySetInnerHTML={{ __html: thisState.aiResponse }}
              className={classes.previewIA}
            />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  startIcon={<AutoDeleteRounded />}
                  onClick={() => {
                    setThisState({
                      ...thisState,
                      aiResponse: null,
                    });
                  }}
                  size="small"
                  color="error"
                >
                  {t("Descartar cambios")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<AutoFixHigh />}
                  onClick={() => {
                    const aiResponse = thisState.aiResponse;

                    setThisState({
                      ...thisState,
                      aiResponse: null,
                    });
                    setTimeout(() => {
                      setThisState({
                        ...thisState,
                        editorContent: aiResponse,
                      });
                    }, 200);
                  }}
                  color="success"
                >
                  {t("Aplicar cambios")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {thisState.waitingForAI && (
            <LinearProgress color="secondary" variant="indeterminate" />
          )}
          {textarea ? (
            <ReactQuill
              readOnly={thisState.waitingForAI}
              autoFocus
              theme="snow"
              modules={{
                toolbar: false,
              }}
              value={thisState.editorContent}
              onChange={handleChange}
              disabled={disabled}
            />
          ) : (
            <ReactQuill
              readOnly={thisState.waitingForAI}
              autoFocus
              theme="snow"
              modules={{
                toolbar: false,
              }}
              value={thisState.editorContent}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomTextEditor;
